.galleryImages {
    flex: 1;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    position: relative;
}

.imagesContainer {
    display: flex;
    flex-direction: row;
    width: max-content;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.imagesContainer::-webkit-scrollbar {
    display: none;
}

.imagesArrows {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    position: absolute;
    bottom: 5%;
}

.arrowIcon {
    color: var(--color-golden);
    font-size: 2rem;
    cursor: pointer;
    background-color: var(--color-black);
    border-radius: 5px;
}

.img {
    min-width: 300px;
    height: 447px;
    margin-right: 30px;
    object-fit: cover;
    will-change: object-position;
}

@media (max-width: 768px) {
    .galleryImages {
        max-width: 100%;
        margin: 20px 0;
    }
}