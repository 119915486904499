.wrapper {
    position: fixed;
    background-color: transparent;
    width: 100%;
    min-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    transition: min-height 0.2s linear;
}

.isScrolled {
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    min-height: 40px;
}

.container {
    width: 100%;
    max-width: 1200px;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logoContainer {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: max-content;
    margin-left: 20px;
    font-size: 26px;
    font-weight: 700;
}

.logo {
    width: 100%;
}

@media (max-width: 960px) {
    .wrapper {
        min-height: 70px;
    }

    .isScrolled {
        min-height: 70px;
    }

    .logoContainer {
        position: fixed;
        top: 1.5rem;
    }
}