.socials {
    width: 100%;
    max-width: 200px;
    height: 100%;
    display: flex;
    justify-content: space-around;
    font-size: 24px;
}

.item {
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    color: var(--main-text-color);
    border-radius: 50px;
    padding: 8px;
}


.item:hover {
    transform: scale(1.2);
    cursor: pointer;
}

@media (min-width: 961px) {
    .item {
        transition: 0.2s ease;
        color: var(--main-text-color);
    }

    .item:hover {
        transform: scale(1.2);
        cursor: pointer;
    }
}