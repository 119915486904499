.chevron {
    position: absolute;
    bottom: 10px;
    font-size: 30px;
    color: var(--main-text-color);
    animation: animateDown infinite 1.8s;
}

@keyframes animateDown {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0)
    }

    40% {
        transform: translateY(5px)
    }

    60% {
        transform: translateY(3px)
    }
}

@media (min-width: 960px) {
    .chevron {
        font-size: 40px;
    }
}