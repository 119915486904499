.navBar {
    display: none;
}

.icons {
    width: 100%;
    justify-content: space-between;
    padding: 0 20px;
    margin-left: 20px;
}

.list {
    font-size: 14px;
}

@media (min-width: 961px) {
    .navBar {
        width: 100%;
        display: flex;
        max-height: 30px;
        justify-content: flex-end;
        align-items: center;
        margin-left: 40px;
    }
}