.modalImgContainer {
    width: 90vw;
    height: 90vh;
}

.modalImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 5;
}