.imageContainer {
    position: relative;
    width: 100%;
    min-height: 100%;
}

.placeholderError {
    position: absolute;
    object-fit: contain;
    width: 100%;
    height: 100%;
    background-color: var(--color-light-gray);
}

.placeholderImage {
    opacity: 0;
    pointer-events: none;
}

.placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: var(--modal-z-index);
}

.mainImage {
    position: absolute;
    object-fit: contain;
}

.opacity {
    opacity: 0 !important;
}

.contain {
    object-fit: contain;
}

.cover {
    object-fit: cover;
}

.none {
    object-fit: none;
}

.fill {
    object-fit: fill;
}

.scale_down {
    object-fit: scale-down;
}