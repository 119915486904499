.overlay {
    position: fixed;
    color: var(--main-text-color);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 250ms cubic-bezier(0.5, 0, 0.5, 1);
    height: 100vh;
    pointer-events: none;
}

.video {
    opacity: 1;
    pointer-events: auto;
}

.wrapper {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.481);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    transition: opacity 250ms cubic-bezier(0.5, 0, 0.5, 1);
}

.navOpen {
    opacity: 1;
}

.container {
    padding: 0px 30px 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: height 0.2s ease;
}

.list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 0;
}