.neon {
    color: var(--accent-color);
    border: var(--accent-color) 2px solid;
    text-shadow: 0 0 2px hsl(0 0% 100% / 0.3), 0 0 9px currentColor;
    box-shadow: inset 0 0 20px 0 var(--accent-color), 0 0 20px 0 var(--accent-color);
    position: relative;
    font-weight: 500;
    animation: breathe 4s ease-out infinite;
}

.withGroundReflection.neon::before {
    pointer-events: none;
    content: "";
    position: absolute;
    background: var(--accent-color);
    top: 120%;
    left: 0;
    width: 100%;
    height: 100%;
    transform: perspective(64px) rotateX(40deg) scale(1, 0.35);
    filter: blur(64px);
    opacity: 0.7;
}

.neon::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 40px 10px var(--accent-color);
    opacity: 0;
    background-color: var(--accent-color);
    z-index: -1;
    transition: opacity 100ms linear;
}

.neon:hover,
.neon:focus {
    color: rgba(255, 255, 255, 0.762);
    text-shadow: none;
}

.neon:hover::before,
.neon:focus::before {
    opacity: 1;
}

.neon:hover::after,
.neon:focus::after {
    opacity: 1;
}

@keyframes breathe {
    0% {
        text-shadow: 0 0 2px hsl(0 0% 100% / 0.3), 0 0 9px currentColor;
        box-shadow: inset 0 0 20px 0 var(--accent-color), 0 0 20px 0 var(--accent-color);
    }

    50% {
        text-shadow: 0 0 4px hsl(0 0% 100% / 0.5), 0 0 18px currentColor;
        box-shadow: inset 0 0 30px 0 var(--accent-color), 0 0 30px 0 var(--accent-color);
    }

    100% {
        text-shadow: 0 0 2px hsl(0 0% 100% / 0.3), 0 0 9px currentColor;
        box-shadow: inset 0 0 20px 0 var(--accent-color), 0 0 20px 0 var(--accent-color);
    }
}