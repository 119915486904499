.item {
    width: fit-content;
    padding: 5px 0;
    display: flex;
    align-items: center;
    min-height: 50px;
    font-weight: 700;
    line-height: 32px;
    color: var(--lightgray-color);
    transition: 0.4s ease;
    position: relative;
}

.undreline {
    display: inline-block;
    position: relative;
}

.undreline::after {
    background: none repeat scroll 0 0 transparent;
    bottom: 2px;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: var(--main-text-color);
    transition: width .2s ease 0s, left .2s ease 0s;
    width: 0;
}

.undreline:hover::after {
    width: 100%;
    left: 0;
}

.item:hover {
    color: var(--main-text-color);
    opacity: 1;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}