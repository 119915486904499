.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
    overflow: hidden;
    padding: 70px 0;
}

@media (max-width:768px) {
    .wrapper {
        flex-direction: column;
    }
}

.title {
    font-size: var(--font-title);
    text-align: center;
}

.description {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    text-align: center;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 15px;
    font-weight: 500;
    letter-spacing: 5px;
}

.btn {
    margin-top: 20px;

}

@media(min-width:481px) {
    .btn {
        margin-top: 30px;
        margin-bottom: 0px;
    }
}