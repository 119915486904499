@import "~@fontsource/montserrat/index.css";
@import "~@fontsource/montserrat/500.css";
@import "~@fontsource/montserrat/700.css";
@import "~@fontsource/yellowtail/index.css";


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

input,
button,
textarea,
select {
    margin: 0;
    font: inherit;
}

body {
    color: var(--main-text-color);
    font-family: 'Montserrat', sans-serif;
    background-color: var(--main-bg-color);
    overflow-x: hidden;

}

:root {
    scroll-behavior: smooth;
    /* Fonts */
    --font-xs: 12px;
    --font-sm: 14px;
    --font-md: 16px;
    --font-lg: 20px;
    --font-xl: 24px;
    --font-title: 32px;

    /* Colors */
    --main-bg-color: #020006;
    --navbar-bg-color: #1f202923;
    --modal-bg-color: #000000;
    --main-text-color: #f1f1f1;
    /* --accent-color: #743AD0; */
    --accent-color: rgb(255, 20, 189);
    --alternative-color: #5FB2F8;
    --gray-color: #6b7688;
    --lightgray-color: #e4e4e4;
    --warning-color: red;
    /* Heights */
    --navbar-height: 50px;

    /* Swiper */
    --sizeindex: calc(1vw + 1vh);
    --swiper-transition: .75s cubic-bezier(.2, .6, 0, 1);
}

a,
a:hover,
a:active,
a:focus,
a:visited {
    text-decoration: none;
    color: inherit;
}