.wrapper {
    position: relative;
}

.container {
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-size: 48px;
    line-height: 1;
}

.description {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    text-align: center;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 15px;
    font-weight: 500;
    letter-spacing: 5px;

}

.btnContainer {
    display: flex;
}

.btnContainer>* {
    width: 150px;
    height: 40px;
    padding: 0;
    margin: 0 3px !important;
}

::-webkit-scrollbar {
    width: 2px;
}

::-webkit-scrollbar-thumb {
    background: rgb(26, 26, 26);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(22, 22, 22);
}


@media(min-width: 481px) {
    .title {
        letter-spacing: 2.5px;
    }
}