.wrapper {
    width: 100%;
    height: 100%;
    padding: var(--navbar-height) 0;
    overflow: hidden;
}

.container {
    width: 100vw;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.imgContainer {
    width: 500px;
    min-width: 400px;
    display: flex;
    justify-content: flex-end;

}

.hero {
    max-width: 100%;
}

.content {
    width: 100%;
    max-width: 600px;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

}

.title {
    font-size: var(--font-title);
    text-align: center;
}

.neon {
    font-size: 40px;
}

.list {
    min-height: 450px;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}

.listItem {
    display: flex;
    align-items: center;
    width: 300px;
}

.icon {
    margin-right: 10px;
    width: 50px;
}

.text {
    text-align: center;
}

.btn {
    max-width: fit-content;
}

@media (max-width: 768px) {
    .container {
        flex-direction: column-reverse;
    }

    .imgContainer {
        margin: 30px;
        justify-content: center;
    }
}

@media (max-width: 650px) {
    .imgContainer {
        width: 90%;
        min-width: 0;
    }

    .content {
        min-width: 0;
    }
}