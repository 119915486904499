.container {
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: var(--navbar-height) 20px;
}

.title {
    font-size: var(--font-title);
    text-align: center;
    margin-bottom: 20px;
}

.list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
}

.listItem {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 300px;
}

.icon {
    margin-right: 10px;
    flex-shrink: 0;
    width: 50px;
    margin-bottom: 5px;
}

.text {
    text-align: center;
}

@media (min-width:481px) {
    .title {
        margin-bottom: 25px;
    }
}