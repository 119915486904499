.list {
    list-style: none;
    color: var(--main-text-color);
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 50px;
}

@media (min-width: 961px) {
    .list {
        display: flex;
        justify-content: space-between;
        max-width: 500px;
    }
}

@media (min-width: 1201px) {
    .list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 5px;
        color: var(--main-text-color);
        font-size: var(--middle-text);
    }
}